@use "mixins" as *
.middleAreaHomePage
  padding-right: 8px
  padding-left: 8px
  padding-top: 10px

  :global(.loader)
    z-index: 1
    top: 0

.homeBannerContainer
  position: relative
  height: var(--home_bannerHeight)
  margin-bottom: 10px
  > div
    width: 100%
    height: 100%
    & :global
      .slick-slider,
      .slick-list,
      .slick-track
        border-radius: 6px

      .banner-item .imageNext
        border-radius: 6px
        width: 100%
        overflow: hidden
        align-items: center
        justify-content: center

        :local(img)
          width: var(--home_bannerImgWidth)
          height: var(--home_bannerHeight)
