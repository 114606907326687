@use "mixins" as *
.faqHeadText
  padding-top: 12px
  &:before
    content: ""
    display: block
    border-top: 1px dotted rgba(153, 164, 176, 0.8)
    margin-bottom: 12px

.faq
  h1
    color: #dfe3e6
    margin-bottom: 10px

  h2
    margin-top: 10px
    margin-bottom: 4px

  p
    font-size: 14px
    line-height: 18px
  ul
    padding: 0 12px 0 24px
    list-style: disc
    font-size: 14px
    line-height: 18px