@use "mixins" as *
.default
  background: var(--casino_leftNavBg)
  border-radius: 10px
  padding: 14px
  font-size: 12px
  margin-bottom: 32px
  .header
    @include flex
    justify-content: space-between
    margin-bottom: 14px

    .showAll
      color: rgb(var(--thirdaryColor))

.carousel
  width: 100%
  font-size: 12px
  margin-bottom: 32px
  position: relative
  .header
    @include flex
    justify-content: space-between
    margin-bottom: 14px
    padding-right: 70px
    .showAll
      color: rgb(var(--secondaryColor))
      &:hover
        color: rgb(var(--thirdaryColor))

.tagName
  @include flex
  font-size: 14px
  color: rgb(var(--secondaryColor))
  @include bold
  text-transform: uppercase
  :global(.svg-icon)
    margin-right: 10px
    svg
      path
        fill: rgb(var(--secondaryColor))

.rowHorizontal, .narrowRow
  background: var(--home_tournamentItemBg)
  border-radius: 4px
  padding: var(--home_tournamentItemPadding)
  margin-bottom: var(--home_tournamentItemMargin)
  position: relative
  .header
    @include flex
    height: 48px
    padding: 0 70px 0 3px
    > a
      color: rgb(var(--primaryColor))
      white-space: nowrap
      &:hover
        color: rgb(var(--secondaryColor))

    h2:nth-child(2)
      text-transform: uppercase
      margin: 0 auto
      padding: 0 10px
      @include ellipsis
      color: rgb(var(--primaryColor))

.rowHorizontal
  min-height: calc(204px + 48px + var(--home_tournamentItemMargin))
  :global(.casino-game-item)
    width: 173px
    height: 204px
    margin-right: 6px
    margin-left: 6px

.narrowRow
  min-height: calc(216px + 48px + var(--home_tournamentItemMargin))

  :global(.casino-game-item)
    --casinoCompact_gameItemBackdropHeight: 100%
    height: 216px
    width: 158px
    border: 6px solid var(--casino_providersBg)
    border-radius: 14px
    margin-right: 4px
    margin-left: 4px

    > span:first-child
      right: 0px
      > span
        border-radius: 8px
        width: 25px
        height: 16px
        svg
          height: 12px
          width: auto
        &:before, &::after
          content: none

    > div
      border-radius: 8px
    > section
      height: 100%
      background: none
      text-align: center
      padding: 0 8px 6px
      justify-content: flex-end
      > i
        pointer-events: all
        min-width: 22px
        height: 22px
        top: 0
        right: 0
        transform: none
        background: rgba(0, 0, 0, .6)
        border-top-right-radius: 7px
        border-bottom-left-radius: 4px

.topWinners
  width: 288px
  min-height: 224px
  position: relative
  .header
    @include flex
    justify-content: space-between
    min-height: 30px

  :global(.loader)
    top: 8px
  :global(.no-game)
    margin: 84px auto 0
    width: fit-content
